import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import Helmet from "react-helmet"
import Map from "../images/map-vignette-2022.png"


const ContactPage = ({data}) => {
    const { markdownRemark: page } = data
    const image = page.frontmatter.thumbnail

    return (
      <div className={`page ${image} contact`}>
        <div className="double-bg"></div>
        <div className={`bg contact-bg ${image}-bg`}></div>
        <Helmet title={page.frontmatter.title}>
          <link rel="stylesheet" href="https://use.typekit.net/bjf1uex.css" />
        </Helmet>
        <Header siteTitle={page.frontmatter.title}/>
          <main>
            <div className="main-content">
                <div className="col-12"><h1>{page.frontmatter.title}</h1></div>
                <div className="content col-7 small-col-12" dangerouslySetInnerHTML={{__html:page.html}} />
                <div className="col-5 small-col-12">
                  <a href={page.frontmatter.mapLink}>
                    <img src={Map} />
                  </a>
                </div>
            </div>
          </main>
        <Footer />
      </div>
    )
}

export default ContactPage

export const pageQuery = graphql`
query ContactPageTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
    html
    frontmatter {
      title
      thumbnail
      mapLink
    }
  }
}
`